// vendors
import { css } from "@emotion/core"
import { Match } from "@reach/router"
import { graphql, Link, useStaticQuery } from "gatsby"
import { between, transparentize, fontFace } from "polished"
import React, { useState } from "react"
// hooks
import useMatchMedia from "../../hooks/useMatchMedia"
// images
import logoSarros from "../../images/logo-sarros.svg"
import LogoFacebook from "../../images/LogoFacebook"
// utils
import {
  colors,
  fontSizes,
  transition,
  zIndices,
  fonts,
} from "../../styles/variables"
import mediaQuery, { greaterThen, lessThen } from "../../utils/media-query"
import regionLinkGenerator from "../../utils/region-link-generator"
import wrapper from "../../utils/wrapper"
import BurgerButton from "../BurgerButton"
import { useContactForm } from "../../contexts/ContactForm"
import LogoInstagram from "../../images/LogoInstagram"
import LogoYoutube from "../../images/LogoYoutube"

const LogoComponent = ({ children, ...rests }) => (
  <Match path="/">
    {(props) => {
      return props.match ? (
        <h1 {...rests}>{children}</h1>
      ) : (
        <Link to="/" {...rests}>
          {children}
        </Link>
      )
    }}
  </Match>
)

const Header = () => {
  const navigation = useStaticQuery(graphql`
    query {
      primary: navigationYaml(key: { eq: "primary" }) {
        items {
          title
          link
          sub
        }
      }

      regions: allSanityRegion {
        nodes {
          meta {
            slug {
              current
            }
          }
          title
          regionNumber
        }
      }
    }
  `)

  const [isOpen, setIsOpen] = useState(false)
  const { open: openContactForm } = useContactForm()

  const breakpoint = 1024

  const isMobile = !useMatchMedia(breakpoint)
  const regions = (navigation.regions || { nodes: [] }).nodes
    .sort((a, b) => a.regionNumber - b.regionNumber)
    .map(
      ({
        title,
        meta: {
          slug: { current },
        },
      }) => ({
        title,
        link: regionLinkGenerator(current),
      })
    )

  const handleClick = () => setIsOpen(!isOpen)

  const handleOpenContactForm = () => {
    setIsOpen(false)

    openContactForm()
  }

  return (
    <header
      css={css`
        top: 0;
        position: sticky;
        z-index: ${zIndices.fixed};
        margin-bottom: ${fontSizes[8] / fontSizes[0]}em;

        ${greaterThen(breakpoint)} {
          ${wrapper.bolt("padding")}
          display: flex;
          justify-content: space-between;
          background-color: ${colors.offWhite};
          height: ${between("85px", "144px", `${breakpoint}px`, "1920px")};
          min-height: 85px;
          max-height: 144px;
          margin-bottom: ${fontSizes[9] / fontSizes[0]}em;
        }
      `}
    >
      <div
        css={css`
          ${lessThen(breakpoint)} {
            ${wrapper.bolt("padding")}
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            grid-template-rows: 85px;
            background-color: ${colors.offWhite};
            z-index: 1;
          }
        `}
      >
        <LogoComponent
          css={css`
            display: block;
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;
            margin: 0;
            width: 128px;
            min-width: 128px;
            max-width: 215px;
            margin-top: -7px;

            ${greaterThen(breakpoint)} {
              margin-top: ${between(
                "-7px",
                "-13.125px",
                `${breakpoint}px`,
                "1920px"
              )};
              width: ${between("128px", "215px", `${breakpoint}px`, "1920px")};
            }
            ${greaterThen(1920)} {
              margin-top: -13.125px;
              width: 215px;
            }
          `}
        >
          <img
            src={logoSarros}
            alt="Sarros"
            css={css`
              display: block;
              margin: auto;
            `}
          />
        </LogoComponent>

        <div
          css={css`
            grid-column: 3 / span 1;
            grid-row: 1 / span 1;
            align-self: center;
            text-align: right;

            ${greaterThen(breakpoint)} {
              opacity: 0;
              pointer-events: none;
            }
          `}
        >
          <BurgerButton onClick={() => handleClick()} isActive={isOpen}>
            Permuter menu
          </BurgerButton>
        </div>
      </div>

      <nav
        css={css`
          display: flex;
          justify-content: center;
          flex-flow: column;

          ${lessThen(breakpoint)} {
            ${wrapper.bolt("padding")}
            overflow: hidden;
            transition: all ${transition.speed.slow};

            ${isOpen
              ? css`
                  background-color: ${transparentize(0, colors.darkTeal)};
                  height: calc(100vh - 85px);
                `
              : css`
                  background-color: ${transparentize(1, colors.darkTeal)};
                  height: 0;
                `}
          }

          ${greaterThen(breakpoint)} {
            justify-content: space-between;
          }
        `}
      >
        <ul
          css={css`
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: ${fontSizes[4] / fontSizes[0]}em;
            font-weight: bold;

            li {
              :hover > ul,
              &.active > ul {
                ${greaterThen(breakpoint)} {
                  opacity: 1;
                  pointer-events: auto;
                }
              }

              :hover > a:after,
              &.active > a:after {
                transform-origin: left;
                transform: scaleX(1);
              }
            }

            & > li > a {
              ${greaterThen(breakpoint)} {
                color: ${colors.darkTeal};
                display: block;

                :after {
                  margin-top: -2px;
                  height: 4px;
                  width: 100%;
                  background-color: ${colors.darkTeal};
                  content: "";
                  display: block;
                  transform: scaleX(0);
                  transition: transform ${transition.speed.default}
                    ${transition.curve.default};
                  transform-origin: left;
                }

                :hover,
                &.active {
                  color: ${colors.darkTeal};
                }

                :hover:after,
                &.active:after {
                  transform-origin: left;
                  transform: scaleX(1);
                }
              }
            }

            a {
              text-decoration: none;

              color: ${colors.offWhite};
              transition: color ${transition.speed.default}
                ${transition.curve.default};
              :hover,
              &.active {
                color: ${colors.blueyGreen};
              }
            }

            ${lessThen(breakpoint)} {
              border-bottom: 2px solid ${colors.offWhite};
              padding-bottom: ${fontSizes[5] / fontSizes[4]}em;
              margin-bottom: ${fontSizes[3] / fontSizes[4]}em;
            }

            ${greaterThen(breakpoint)} {
              font-size: ${fontSizes[4] / fontSizes[1]}em;
              display: flex;
              order: 2;
              flex-grow: 1;
            }
          `}
        >
          {navigation.primary.items.map(({ title, link, sub }, index) => (
            <li
              key={index}
              css={css`
                position: relative;

                ${greaterThen(breakpoint)} {
                  margin-right: ${fontSizes[5] / fontSizes[4]}em;

                  :last-of-type {
                    margin-right: 0;
                  }
                }
              `}
            >
              <Link
                to={`/${link}`}
                activeClassName="active"
                partiallyActive={true}
              >
                {title}
              </Link>

              {sub === "regions" && (
                <ul
                  css={css`
                    top: 100%;
                    opacity: 0;
                    position: absolute;
                    list-style: none;
                    margin: 0;
                    margin-left: -${fontSizes[6] / fontSizes[3]}em;
                    padding: ${fontSizes[5] / fontSizes[3]}em
                      ${fontSizes[6] / fontSizes[3]}em;
                    background-color: ${colors.darkTeal};
                    color: ${colors.offWhite};
                    font-weight: 600;
                    font-size: ${fontSizes[3] / fontSizes[4]}em;
                    pointer-events: none;
                    overflow-y: scroll;
                    max-height: calc(
                      100vh - 144px - ${(fontSizes[5] / fontSizes[3]) * 2}em
                    );

                    ::-webkit-scrollbar {
                      display: none;
                    }

                    transition: all ${transition.speed.fast}
                      ${transition.curve.default};

                    li {
                      margin: 0.5em 0;
                      :first-child {
                        margin-top: 0;
                      }

                      :last-child {
                        margin-bottom: 0;
                      }
                    }
                  `}
                >
                  {regions.map((region) => (
                    <li>
                      <Link
                        css={css`
                          display: block;
                          word-break: keep-all;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        `}
                        partiallyActive={true}
                        activeClassName="active"
                        to={region.link}
                      >
                        {region.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
          {isMobile && (
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          )}
        </ul>

        <ul
          css={css`
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: ${fontSizes[3] / fontSizes[0]}em;

            li {
              display: flex;
              align-items: center;
            }

            /* li:nth-child(n + 3) {
              margin-top: 0.75em;
            } */

            a {
              line-height: 0;
              text-decoration: none;
              color: ${colors.offWhite};
            }

            ${greaterThen(breakpoint)} {
              order: 1;
              display: flex;
              justify-content: flex-end;
              flex-grow: 1;
              align-items: center;
              font-size: ${fontSizes[1] / fontSizes[1]}em;

              li {
                display: inline-flex;
              }

              li:not(:first-child) {
                margin-top: 0;
                margin-left: 1em;
              }

              a {
                color: ${colors.darkTeal};
              }
            }
          `}
        >
          {!isMobile && (
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          )}

          {isMobile && (
            <li>
              <button
                onClick={handleOpenContactForm}
                css={css`
                  appearance: none;
                  border: none;
                  background: none;
                  color: inherit;
                  cursor: pointer;
                  font-family: ${fonts.accent};
                  font-size: ${fontSizes[1] / fontSizes[0]}em;
                  color: ${colors.offWhite};
                  text-align: left;
                  padding: 0;
                  margin-bottom: 1em;
                `}
              >
                Demande d'information&nbsp;→
              </button>
            </li>
          )}

          <li>
            <ul
              css={css`
                display: inline-flex;
                padding-left: 0em;

                li:not(:first-child) {
                  margin-left: 0.7em;
                }
              `}
            >
              <li>
                <a href="https://www.facebook.com/equipesarros/">
                  <LogoFacebook
                    css={css`
                      width: ${fontSizes[3] / fontSizes[3]}em;
                      fill: ${colors.offWhite};

                      ${greaterThen(breakpoint)} {
                        width: ${fontSizes[1] / fontSizes[1]}em;
                        fill: ${colors.darkTeal};
                      }
                    `}
                  />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/sarros_qc/">
                  <LogoInstagram
                    css={css`
                      width: ${fontSizes[3] / fontSizes[3]}em;
                      fill: ${colors.offWhite};

                      ${greaterThen(breakpoint)} {
                        width: ${fontSizes[1] / fontSizes[1]}em;
                        fill: ${colors.darkTeal};
                      }
                    `}
                  />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCCItycdtzuXcnKi3EVBaU8g/">
                  <LogoYoutube
                    css={css`
                      width: ${21 / fontSizes[0]}em;
                      fill: ${colors.offWhite};

                      ${greaterThen(breakpoint)} {
                        fill: ${colors.darkTeal};
                      }
                    `}
                  />
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
